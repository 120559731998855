import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, Typography, IconButton, useMediaQuery, useTheme, Chip, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Sidebar from './Sidebar';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCredits } from '../features/auth/authSlice';

const APPBAR_HEIGHT = 64; // Define AppBar height
const DRAWER_WIDTH = 240; // Define drawer width for mobile view

const Layout = ({ children }) => {
  const { layout, mode } = useSelector((state) => state.auth.settings);
  const { role, credits } = useSelector((state) => state.auth);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery('(max-width:1024px)');
  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'dealer' || role === 'superreseller' || role === 'reseller') {
      dispatch(fetchCredits());
    }
  }, [dispatch, role]);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileNavClick = () => {
    if (isMobileView) {
      setMobileOpen(false);
    }
  };

  const getCreditsColor = (credits) => {
    if (credits > 30) return 'success';
    if (credits > 0) return 'warning';
    return 'error';
  };

  const getPanelName = () => {
    switch (role) {
      case 'admin':
        return 'Admin Panel';
      case 'superreseller':
        return 'Super Reseller Panel';
      case 'reseller':
        return 'Reseller Panel';
      case 'dealer':
      default:
        return 'Dealer Panel';
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar 
        position="fixed" 
        sx={{ 
          backgroundColor: 'transparent',
          boxShadow: 'none',
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)', // for Safari
        }}
      >
        <Toolbar>
          {isMobileView ? (
            <IconButton
              color={mode === 'light' ? 'default' : 'inherit'}
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              color={mode === 'light' ? 'default' : 'inherit'}
              aria-label="toggle sidebar"
              edge="start"
              onClick={handleToggleCollapse}
              sx={{ mr: 2 }}
            >
              {isCollapsed ? 
                (layout === 'left' ? <ChevronRightIcon /> : <ChevronLeftIcon />) :
                (layout === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />)
              }
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, color: 'text.primary' }}>
            {getPanelName()}
          </Typography>
          {(isMobileView || isCollapsed) && (role === 'dealer' || role === 'superreseller' || role === 'reseller') && (
            <Chip
              icon={<AttachMoneyIcon />}
              label={credits}
              color={getCreditsColor(credits)}
              variant="outlined"
            />
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', flexGrow: 1, pt: `${APPBAR_HEIGHT}px` }}>
        {isMobileView ? (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', 
                width: DRAWER_WIDTH 
              }
            }}
          >
            <Sidebar 
              position={layout} 
              isCollapsed={false}
              appBarHeight={APPBAR_HEIGHT}
              credits={credits}
              onMobileNavClick={handleMobileNavClick}
            />
          </Drawer>
        ) : (
          <Sidebar 
            position={layout} 
            isCollapsed={isCollapsed}
            appBarHeight={APPBAR_HEIGHT}
            credits={credits}
          />
        )}
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1, 
            p: isMobileView ? 0 : 3,  // Reduce padding on mobile
            m: isMobileView ? 1 : 2,  // Reduce margin on mobile
            width: '100%',
            boxSizing: 'border-box',
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { 
  Typography, 
  Box, 
  Paper, 
  Grid, 
  Card, 
  CardContent, 
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  CircularProgress,
  styled,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  InputAdornment,
  IconButton,
  Divider,
  useTheme,
  alpha,
  Snackbar,
  Alert,
} from '@mui/material';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import api from '../services/api';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { fetchCredits } from '../features/auth/authSlice'; // We'll create this action
import RefreshIcon from '@mui/icons-material/Refresh';

const cardStyle = {
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  transition: '0.3s',
  '&:hover': {
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
  },
};

const TypeSpan = styled('span')(({ theme, type }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: 
    type === 'USED' ? theme.palette.error.light :
    type === 'RETRIEVED' ? theme.palette.success.light :
    'transparent',
  color: 
    type === 'USED' || type === 'RETRIEVED' ? theme.palette.common.white :
    theme.palette.text.primary,
}));

const ExpirySpan = styled('span')(({ theme, expiryStatus }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: 
    expiryStatus === 'expired' ? theme.palette.error.light :
    expiryStatus === 'expiring' ? theme.palette.warning.light :
    theme.palette.success.light,
  color: 
    expiryStatus === 'expired' ? theme.palette.error.contrastText :
    expiryStatus === 'expiring' ? theme.palette.warning.contrastText :
    theme.palette.success.contrastText,
}));

const ContentSpan = styled('span')(({ theme, isRecent }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  backgroundColor: isRecent ? theme.palette.info.light : theme.palette.warning.light,
  color: isRecent ? theme.palette.info.contrastText : theme.palette.warning.contrastText,
  marginLeft: '8px',
}));

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['0', '0', ':' , '1', 'A', ':', '7', '9', ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const PhoneMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const UpdateClient = () => {
  const { clientName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('created');
  const [order, setOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [clientData, setClientData] = useState({
    name: '',
    password: '',
    mac: '',
    ministra: false,
    alt_player: false,
    expiry: '',
    status: false,
    phone: '',
    description: '',
    package_selection: '',
    locked: false,
    credits: 0,  // Set the default value to 0
  });
  const [deviceInfo, setDeviceInfo] = useState({
    stb_sn: '',
    stb_type: '',
    version: '',
    watching: '',
    tariff_plan: '',
    online: false,
    last_active: '',
    time_last_play_tv: '',
    time_last_play_video: '',
    last_played_tv_content: '',
    last_played_vod_content: '',
    locked_to_ip: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [retrieveCredits, setRetrieveCredits] = useState(0);  // Changed to 0

  useEffect(() => {
    fetchTransactions();
    fetchClientData();
  }, [clientName]);

  const fetchTransactions = async () => {
    try {
      const response = await api.get(`/api/history/${clientName}`);
      setTransactions(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setLoading(false);
    }
  };

  const fetchClientData = async () => {
    try {
      const response = await api.get(`/api/client/${clientName}`);
      const data = response.data;
      setClientData({
        name: data.name,
        password: data.password,
        mac: data.mac,
        o_mac: data.o_mac,
        ministra: data.ministra,
        alt_player: data.alt_player,
        expiry: data.expiry,
        status: data.status,
        phone: data.phone,
        description: data.description,
        package_selection: data.package_selection,
        locked: data.locked,
        credits: 0,  // Set the default value to 0
      });
      setDeviceInfo({
        stb_sn: data.stb_sn,
        stb_type: data.stb_type,
        version: data.version,
        watching: data.watching,
        tariff_plan: data.tariff_plan,
        online: data.online,
        last_active: data.last_active,
        time_last_play_tv: data.time_last_play_tv,
        time_last_play_video: data.time_last_play_video,
        last_played_tv_content: data.last_played_tv_content,
        last_played_vod_content: data.last_played_vod_content,
        locked_to_ip: data.locked_to_ip,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client data:', error);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setClientData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleUpdateClient = async () => {
    try {
      const response = await api.post('/api/saveclient', {
        login: clientData.name,
        password: clientData.password,
        o_mac: clientData.o_mac,
        stb_mac: clientData.mac,
        alt_player: clientData.alt_player,
        ministra: clientData.ministra,
        months: clientData.credits,
        desc: clientData.description,
        phone: clientData.phone,
        status: clientData.status,
        package_selection: clientData.package_selection,
        locked: clientData.locked
      });

      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: `Client ${response.data.name} updated successfully!`,
          severity: 'success'
        });
        
        // Fetch updated credits
        dispatch(fetchCredits());
        
        // Redirect after a short delay
        setTimeout(() => {
          navigate('/clients');
        }, 2000);
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      console.error('Error updating client:', error);
      setSnackbar({
        open: true,
        message: 'Error updating client. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleRetrieveCreditsChange = (event) => {
    setRetrieveCredits(event.target.value);
  };

  const handleRetrieveCredits = async () => {
    if (retrieveCredits === 0) {
      setSnackbar({
        open: true,
        message: 'Please select credits to retrieve',
        severity: 'warning'
      });
      return;
    }

    try {
      const response = await api.post('/api/retrieve', {
        login: clientData.name,
        credits: retrieveCredits
      });

      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: `Successfully retrieved ${retrieveCredits} credits`,
          severity: 'success'
        });
        
        // Update sidebar credits
        dispatch(fetchCredits());
        
        // Refresh client data
        fetchClientData();
        
        // Refresh transactions
        fetchTransactions();
        
        // Reset the dropdown
        setRetrieveCredits(0);
      } else {
        throw new Error('Retrieve failed');
      }
    } catch (error) {
      console.error('Error retrieving credits:', error);
      setSnackbar({
        open: true,
        message: 'Error retrieving credits. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleResetClient = async () => {
    try {
      const response = await api.post('/api/resetclient', { name: clientName });
      
      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: 'Client reset successfully',
          severity: 'success'
        });
        
        // Reload client data
        fetchClientData();
      } else {
        throw new Error('Reset failed');
      }
    } catch (error) {
      console.error('Error resetting client:', error);
      setSnackbar({
        open: true,
        message: 'Error resetting client. Please try again.',
        severity: 'error'
      });
    }
  };

  const filteredTransactions = transactions.filter(transaction =>
    Object.values(transaction).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedTransactions = filteredTransactions.sort((a, b) => {
    if (b[orderBy] < a[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  });

  const headCells = [
    { id: 'created', label: 'Date' },
    { id: 'type', label: 'Type' },
    { id: 'credits', label: 'Credits', numeric: true },
    { id: 'description', label: 'Description' },
    { id: 'expiry', label: 'Expiry' },
  ];

  const availablePackages = [
    { value: 1, label: 'All Packages' },
    { value: 7, label: 'All Packages/No Adults' },
    { value: 2, label: 'English/Sports/Adults' },
    { value: 3, label: 'South Asian/English/Sports/Adults' },
    { value: 4, label: 'South Asian/English/Sports/No Adults' },
    { value: 5, label: 'English/Portuguese/Brazil/Sports/Adults' },
    { value: 13, label: 'English/Sports/No Adults' },
    { value: 14, label: 'English/Sports/Portuguese/Brasil/Adults' },
    { value: 15, label: 'English/Sports/Portuguese/Brasil/No Adults' },
    { value: 16, label: 'English/Sports/Spanish/Adults' },
    { value: 17, label: 'English/Sports/Spanish/No Adults' },
    { value: 18, label: 'English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/Adults' },
    { value: 19, label: 'English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/No Adults' },
    { value: 20, label: 'English/Sports/Spanish/Portuguese/Italian/Adults' },
    { value: 21, label: 'English/Sports/Spanish/Portuguese/Italian/No Adults' },
    { value: 22, label: 'English/Sports/Caribbean/Adults' },
    { value: 23, label: 'English/Sports/Caribbean/No Adults' },
    { value: 24, label: 'English/Sports/Caribbean/South Asian/No Adults' },
    { value: 25, label: 'English/Sports/Caribbean/South Asian/Adults' },
    { value: 26, label: 'English/Sports/Ex Yugoslavia/Adults' },
    { value: 27, label: 'English/Sports/Ex Yugoslavia/No Adults' },
    { value: 28, label: 'English/Sports/Portuguese/Brasil/Italian/Spanish/Adults' },
    { value: 32, label: 'English/Sports/Portuguese/Brasil/Italian/Spanish/No Adults' },
    { value: 33, label: 'English/Sports/Italian/Adults' },
    { value: 34, label: 'English/Sports/Italian/No Adults' },
    { value: 35, label: 'English/Sports/Latino/Mexico/Spain/South America/Adults' },
    { value: 36, label: 'English/Sports/Latino/Mexico/Spain/South America/No Adults' },
    { value: 37, label: 'English/Sports/Arabic/No Adults' },
    { value: 38, label: 'English/Sports/Arabic/Adults' },
    { value: 39, label: 'English/Sports/Greek/Adults' },
    { value: 40, label: 'English/Sports/Greek/No Adults' },
    { value: 41, label: 'English/Sports/Polish/Romania/Ex Yugoslavia/Adults' },
    { value: 42, label: 'English/Sports/Polish/Romania/Ex Yugoslavia/No Adults' },
    { value: 43, label: 'English/Sports/French CA/African/French/No Adults' },
    { value: 44, label: 'English/Sports/French CA/African/French/Adults' },
    { value: 45, label: 'English/Sports/German/No Adults' },
    { value: 46, label: 'English/Sports/German/Adults' },
    { value: 47, label: 'English/Sports/Vietnamese/Korean/Chinese/Filipino/No Adults' },
    { value: 48, label: 'English/Sports/Vietnamese/Korean/Chinese/Filipino/Adults' },
    { value: 49, label: 'English/Sports/Hindi/Urdu/Punjabi/Adults' },
    { value: 50, label: 'English/Sports/Hindi/Urdu/Punjabi/No Adults' },
  ];

  const cardHeaderStyle = {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.common.black, // Changed to black
    padding: '16px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };

  const cardTitleStyle = {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.common.black, // Ensure the title is also black
  };

  const getExpiryStatus = (expiryDate) => {
    const now = new Date();
    const expiry = new Date(expiryDate);
    const daysUntilExpiry = Math.ceil((expiry - now) / (1000 * 60 * 60 * 24));

    if (daysUntilExpiry < 0) {
      return 'expired';
    } else if (daysUntilExpiry <= 10) {
      return 'expiring';
    } else {
      return 'active';
    }
  };

  const isExpired = getExpiryStatus(clientData.expiry) === 'expired';

  return (
    <Box sx={{ width: '100%', padding: 1 }}>
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={5}>
          <Grid container direction="column" spacing={3}>
            {/* Update Client Card */}
            <Grid item>
              <Card sx={cardStyle}>
                <CardHeader 
                  title="Update Client"
                  subheader={clientName}
                  titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
                  subheaderTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                  action={
                    clientData.expiry && (
                      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <ExpirySpan expiryStatus={getExpiryStatus(clientData.expiry)}>
                          {moment(clientData.expiry).format('YYYY-MM-DD')}
                        </ExpirySpan>
                      </Box>
                    )
                  }
                  sx={{
                    ...cardHeaderStyle,
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiCardHeader-action': {
                      margin: 0,
                      alignSelf: 'center',
                    },
                  }}
                />
                <Divider />
                <CardContent>
                  <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <FormControlLabel
                        control={<Checkbox checked={clientData.status} onChange={handleInputChange} name="status" />}
                        label="Active"
                        disabled={isExpired}
                      />
                      <FormControlLabel
                        control={<Checkbox checked={clientData.locked} onChange={handleInputChange} name="locked" />}
                        label="IP Lock"
                        disabled={isExpired}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <FormControlLabel
                        control={<Checkbox checked={clientData.ministra} onChange={handleInputChange} name="ministra" />}
                        label="Ministra"
                        disabled={isExpired}
                      />
                      <FormControlLabel
                        control={<Checkbox checked={clientData.alt_player} onChange={handleInputChange} name="alt_player" />}
                        label="Alt Player"
                        disabled={isExpired}
                      />
                    </Box>
                  </Box>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={clientData.password}
                    onChange={handleInputChange}
                    disabled={isExpired}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disabled={isExpired}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Description"
                    name="description"
                    multiline
                    rows={2}
                    value={clientData.description}
                    onChange={handleInputChange}
                    disabled={isExpired}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Phone Number"
                    name="phone"
                    value={clientData.phone}
                    onChange={handleInputChange}
                    disabled={isExpired}
                    InputProps={{
                      inputComponent: PhoneMaskCustom,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                  {!clientData.ministra && (
                    <TextField
                      fullWidth
                      margin="normal"
                      label="MAC Address"
                      name="mac"
                      value={clientData.mac}
                      onChange={handleInputChange}
                      disabled={isExpired}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Credits</InputLabel>
                    <Select
                      value={clientData.credits}
                      onChange={handleInputChange}
                      name="credits"
                    >
                      <MenuItem value={0}>Add months</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => (
                        <MenuItem key={month} value={month}>{month} Month{month > 1 ? 's' : ''}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Package</InputLabel>
                    <Select
                      value={clientData.package_selection}
                      onChange={handleInputChange}
                      name="package_selection"
                      disabled={isExpired}
                    >
                      {availablePackages.map(pkg => (
                        <MenuItem key={pkg.value} value={pkg.value}>{pkg.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateClient}
                    sx={{ mt: 2 }}
                  >
                    Update Client
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Retrieve Credits Card */}
            <Grid item>
              <Card sx={cardStyle}>
                <CardHeader 
                  title="Retrieve Credits" 
                  titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
                  sx={cardHeaderStyle}
                />
                <Divider />
                <CardContent>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Credits to Retrieve</InputLabel>
                    <Select
                      value={retrieveCredits}
                      onChange={handleRetrieveCreditsChange}
                      name="retrieveCredits"
                      disabled={isExpired}
                    >
                      <MenuItem value={0}>Select credits</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => (
                        <MenuItem key={month} value={month}>{month} Month{month > 1 ? 's' : ''}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRetrieveCredits}
                    sx={{ mt: 2 }}
                    disabled={retrieveCredits === 0 || isExpired}
                  >
                    Retrieve
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={7}>
          <Grid container direction="column" spacing={3}>
            {/* Transactions Card */}
            <Grid item>
              <Card sx={cardStyle}>
                <CardHeader 
                  title="Transactions" 
                  titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
                  action={
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      sx={{ width: { xs: '100%', sm: 200 }, mt: { xs: 2, sm: 0 } }}
                    />
                  }
                  sx={{
                    ...cardHeaderStyle,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    '& .MuiCardHeader-action': {
                      width: { xs: '100%', sm: 'auto' },
                      marginTop: { xs: 2, sm: 0 },
                    },
                  }}
                />
                <Divider />
                <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
                  <TableContainer>
                    <Table sx={{ minWidth: { xs: 300, sm: 650 } }} size="small">
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'right' : 'left'}
                              sortDirection={orderBy === headCell.id ? order : false}
                              sx={{ 
                                display: { 
                                  xs: headCell.id === 'created' || headCell.id === 'type' || headCell.id === 'credits' ? 'table-cell' : 'none',
                                  sm: 'table-cell' 
                                } 
                              }}
                            >
                              <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => handleRequestSort(headCell.id)}
                              >
                                {headCell.label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        ) : sortedTransactions.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              No transactions found
                            </TableCell>
                          </TableRow>
                        ) : (
                          sortedTransactions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((transaction, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ display: { xs: 'table-cell', sm: 'table-cell' } }}>
                                  {moment(transaction.created).format('YYYY-MM-DD')}
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'table-cell', sm: 'table-cell' } }}>
                                  <TypeSpan type={transaction.type}>
                                    {transaction.type}
                                  </TypeSpan>
                                </TableCell>
                                <TableCell align="right" sx={{ display: { xs: 'table-cell', sm: 'table-cell' } }}>
                                  {transaction.credits}
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                  {transaction.description}
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                  {moment(transaction.expiry).format('YYYY-MM-DD')}
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredTransactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                        display: { xs: 'none', sm: 'block' }
                      },
                      '.MuiTablePagination-select, .MuiTablePagination-actions': {
                        marginLeft: { xs: 0, sm: 2 }
                      }
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Device Information Card */}
            <Grid item>
              <Card sx={cardStyle}>
                <CardHeader 
                  title="Device Information" 
                  titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
                  action={
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleResetClient}
                      startIcon={<RefreshIcon />}
                      disabled={isExpired}
                    >
                      Reset
                    </Button>
                  }
                  sx={{
                    ...cardHeaderStyle,
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiCardHeader-action': {
                      margin: 0,
                      alignSelf: 'center',
                    },
                  }}
                />
                <Divider />
                <CardContent>
                  <Typography><strong>STB Serial Number:</strong> {deviceInfo.stb_sn}</Typography>
                  <Typography><strong>STB Type:</strong> {deviceInfo.stb_type}</Typography>
                  <Typography><strong>Version:</strong> {deviceInfo.version}</Typography>
                  <Typography><strong>Online:</strong> {deviceInfo.online ? 'Yes' : 'No'}</Typography>
                  <Typography><strong>Last Active:</strong> {moment(deviceInfo.last_active).format('MMM D, YYYY h:mm A')}</Typography>
                  <Typography>
                    <strong>Last watched TV:</strong> {deviceInfo.time_last_play_tv ? moment(deviceInfo.time_last_play_tv).format('MMM D, YYYY h:mm A') : 'N/A'}
                    {deviceInfo.last_played_tv_content && (
                      <ContentSpan isRecent={moment().diff(moment(deviceInfo.time_last_play_tv), 'hours') <= 24}>
                        {deviceInfo.last_played_tv_content}
                      </ContentSpan>
                    )}
                  </Typography>
                  <Typography>
                    <strong>Last watched VOD:</strong> {deviceInfo.time_last_play_video ? moment(deviceInfo.time_last_play_video).format('MMM D, YYYY h:mm A') : 'N/A'}
                    {deviceInfo.last_played_vod_content && (
                      <ContentSpan isRecent={moment().diff(moment(deviceInfo.time_last_play_video), 'hours') <= 24}>
                        {deviceInfo.last_played_vod_content}
                      </ContentSpan>
                    )}
                  </Typography>
                  <Typography><strong>Locked to IP:</strong> {deviceInfo.locked_to_ip || 'N/A'}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UpdateClient;
